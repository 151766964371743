import React from 'react';

const Face = () => {
  return (
    <g>
      <path
        fill="#FFD9C2"
        stroke="#E5AF90"
        strokeWidth="3"
        strokeMiterlimit="10"
        d="M23,127.5L23,127.5c-4.1,0-7.5-3.4-7.5-7.5v-8
		c0-4.1,3.4-7.5,7.5-7.5h0c4.1,0,7.5,3.4,7.5,7.5v8C30.5,124.1,27.1,127.5,23,127.5z"
      />
      <path
        fill="#FFD9C2"
        stroke="#E5AF90"
        strokeWidth="3"
        strokeMiterlimit="10"
        d="M177,127.5L177,127.5c-4.1,0-7.5-3.4-7.5-7.5v-8
		c0-4.1,3.4-7.5,7.5-7.5l0,0c4.1,0,7.5,3.4,7.5,7.5v8C184.5,124.1,181.1,127.5,177,127.5z"
      />
      <path
        fill="#FFD9C2"
        stroke="#E5AF90"
        strokeWidth="3"
        strokeMiterlimit="10"
        d="M100,180.5c-17.6,0-37-8-52-21.3c-17-15.1-26.4-35.3-26.5-56.9c0.1-33,24.5-59.8,54.4-59.8h48.2
			c29.9,0,54.3,26.8,54.4,59.8c-0.1,21.6-9.5,41.8-26.5,56.9C137,172.5,117.6,180.5,100,180.5z"
      />
      <path
        fill="none"
        stroke="#333333"
        strokeWidth="4"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M89,126c0,0,4.1,4,11,4
		s11-4,11-4"
      />
      <path
        fill="#FEB6B7"
        d="M149,135h-17c-1.6,0-3-1.4-3-3l0,0c0-1.6,1.4-3,3-3h17c1.6,0,3,1.4,3,3l0,0C152,133.6,150.6,135,149,135z"
      />
      <path
        fill="#FEB6B7"
        d="M68,135H51c-1.6,0-3-1.4-3-3l0,0c0-1.6,1.3-3,3-3h17c1.7,0,3,1.4,3,3l0,0C71,133.6,69.7,135,68,135z"
      />
      <path
        fill="#E5AF90"
        d="M108,146H92c-1.7,0-3-1.4-3-3l0,0c0-1.6,1.3-3,3-3h16c1.7,0,3,1.4,3,3l0,0C111,144.6,109.7,146,108,146z"
      />
      <path
        fill="#F2F2F2"
        d="M29,110.5l1.1,0.2c1.1,0.2,1.9,1.1,2,2.1c0.5,5.1,1.7,19,0.9,18.2c-4.6-4.6-6.3-13.2-6.8-17.8
		C26,111.6,27.4,110.3,29,110.5z"
      />
    </g>
  );
};

export default Face;
