import React, { useEffect, useState } from 'react';
import { animated as a } from 'react-spring';

const Eyes = ({ type, movement }) => {
  const [blink, setBlink] = useState(false);

  //initial blink
  useEffect(() => {
    const timing = setTimeout(() => {
      setBlink(true);
    }, 800);
    return () => clearTimeout(timing);
  }, []);

  useEffect(() => {
    let duration = 100;
    if (!blink) {
      duration = Math.random() * 2000 + 2000;
    }
    const timing = setTimeout(() => {
      setBlink(s => !s);
    }, duration);
    return () => clearTimeout(timing);
  }, [blink]);

  return (
    <a.g
      style={{
        transform: movement.interpolate((x, y) => {
          x = x / 40;
          y = y / 40;
          x = x < -6.5 ? -6.5 : x > 6.5 ? 6.5 : x;
          y = y < -6.5 ? -6.5 : y > 6.5 ? 6.5 : y;
          return `translate(${x}px,${y}px)`;
        }),
      }}
    >
      {type === 'round' && blink ? (
        <>
          <path
            fill="#333333"
            d="M57.5,111h12c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5h-12c-0.8,0-1.5-0.7-1.5-1.5l0,0
  C56,111.7,56.7,111,57.5,111z"
          />
          <path
            fill="#333333"
            d="M129.5,111h12c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5h-12c-0.8,0-1.5-0.7-1.5-1.5l0,0
  C128,111.7,128.7,111,129.5,111z"
          />
        </>
      ) : type === 'round' && !blink ? (
        <>
          <circle fill="#333333" cx="63.5" cy="112.5" r="7.5" />
          <circle fill="#333333" cx="135.5" cy="112.5" r="7.5" />
        </>
      ) : type === 'rect' && blink ? (
        <>
          <path
            display="inline"
            fill="#333333"
            d="M60.5,111h6c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5h-6c-0.8,0-1.5-0.7-1.5-1.5
		l0,0C59,111.7,59.7,111,60.5,111z"
          />
          <path
            display="inline"
            fill="#333333"
            d="M132.5,111h6c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5h-6c-0.8,0-1.5-0.7-1.5-1.5
		l0,0C131,111.7,131.7,111,132.5,111z"
          />
        </>
      ) : type === 'rect' && !blink ? (
        <>
          <path
            display="inline"
            fill="#333333"
            d="M59,114.5v-4c0-2.5,2-4.5,4.5-4.5h0c2.5,0,4.5,2,4.5,4.5v4c0,2.5-2,4.5-4.5,4.5h0
		C61,119,59,117,59,114.5z"
          />
          <path
            display="inline"
            fill="#333333"
            d="M131,114.5v-4c0-2.5,2-4.5,4.5-4.5l0,0c2.5,0,4.5,2,4.5,4.5v4c0,2.5-2,4.5-4.5,4.5l0,0
		C133,119,131,117,131,114.5z"
          />
        </>
      ) : null}
    </a.g>
  );
};

Eyes.defaultProps = {
  type: 'round',
};

export default Eyes;
