import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import AboutMe from '../view/aboutme';
import '../style/style.scss';

const IndexPage = props => (
  <Layout {...props}>
    <AboutMe />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`;
