import React from 'react';

import { randomPick as rp } from '../../helper/helper';

import Face from './face';
import Eyes from './eyes';
import Head from './head';
import Glasses from './glasses';

const Avatar = ({ style, theme, movement }) => {
  return (
    <svg
      id="avatar"
      style={style}
      version="1.2"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      shapeRendering="geometricPrecision"
    >
      <Face />
      <Eyes movement={movement} />
      <Head type="default" />
      {typeof window !== `undefined` ? (
        <Glasses
          shade={theme === 'dark' ? false : true}
          type={rp(['round', 'square'])}
        />
      ) : null}
    </svg>
  );
};

Avatar.defaultProps = {
  theme: 'dark',
  style: {},
};

export default Avatar;
