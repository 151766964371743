import React from 'react';
const Head = ({ type, movement }) => {
  return (
    <g>
      {type === 'default' ? (
        <path
          fill="#666666"
          stroke="#333333"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M143.5,29.5c16,1,41,33.7,41,72c0,4.2-6.4,2-10,1s-3.4-13-5-13c-2.5,0,3,9.5-1,10c-2,0.2-11.2,1-15,0c-2.6-0.7-3.7-13.5-6-12
		s5.2,9.8,1,12c-3.8,2-19.1,4.2-23,3c-6.5-2-6.1-21-8-21c-1.8,0,6.6,17.1,2,19c-4.7,2-19.1,0.2-21.5-0.3c-2.4-0.5-0.6-17.6-2.2-16.7
		s0.6,15.8-2.5,17.1s-11.9,2.6-14.8,1.9c-3.8-1-0.7-12-0.7-12s-0.9,6.4-3.3,9c-2.5,2.7-9,4-14,2c-3.7-1.5-2.5-14-2.5-14
		s-1.5,13.3-4.7,16c-2.4,1.9-38.3,8.2-38.3-5.6C15,46.4,53.1,19,100,19c11.8,0,28.4,4.2,30.5,3.5c3-1-3-16,2-8c3,5,5,10,7,10
		c2,1,16-3,16,0C155.5,26.5,142.5,28.5,143.5,29.5z"
        />
      ) : type === 'alt' ? (
        <path
          display="inline"
          fill="#666666"
          stroke="#333333"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M100.5,97.5c17.5-4.2,42.2-13.1,49.5-14c1.4-0.2,2.8-0.1,4.2,0.3c14.5,3.5,27.8,31.5,27.8,16.7c0-57.4-33.2-82-80.1-82
		S20,43.1,20,100.5c0,15.3,10,2,30.4-1c15.1-2.2,12.2,1,34.2-1c2.5-0.1,8.7-1,9-1l10.8-10l11-10L100.5,97.5z"
        />
      ) : type === 'metro' ? (
        <path
          display="inline"
          fill="#A67C52"
          stroke="#534741"
          strokeWidth="3"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
		M185,104c0,16.2-41.7-5-61.5-7.5c-8-1-17.9,11-31,10c-13-1,14-10,12-15s-9.8-4.7-20-1c-11,4-69.5,28.8-69.5,13.5
		c0-57.4,38.1-88,85-88S185,46.6,185,104z"
        />
      ) : type === 'poopy' ? (
        <path
          display="inline"
          fill="#666666"
          stroke="#333333"
          strokeWidth="3"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="
		M181,101c0,14-14.8-8.7-52.5-5.5c-10.1,0.9-23.4,5.1-42,6c-20.9,1.7-19.3-2.9-33.6-0.9C33.5,103.2,20,114.3,20,101
		c0-49.8,64.9-69.3,69.3-74.9c5.4-6.9-12.6,7-3.9-6.9C105.5-12.9,181,57.3,181,101z"
        />
      ) : null}
    </g>
  );
};

Head.defaultProps = {
  type: 'default',
};

export default Head;
