import React from 'react';

import Avatar from '../components/avatar/';

import { useSpring } from 'react-spring';

const AboutMe = ({ theme }) => {
  const calc = (x, y) => {
    return [x - window.innerWidth / 2, y - 316 / 2];
  };

  const [{ pos }, set] = useSpring(() => ({
    pos: [0, 0],
    config: { mass: 1, tension: 200 },
  }));

  return (
    <>
      <div
        className=" position-relative"
        onMouseMove={({ clientX: x, clientY: y }) => set({ pos: calc(x, y) })}
        onPointerOut={() => set({ pos: [0, 0] })}
        role="presentation"
      >
        <div className="row">
          <div className="col text-center">
            <div className="avatar">
              <Avatar theme={theme} movement={pos} />
            </div>
          </div>
        </div>
        <div className="row about-me">
          <div className="description">
            <h1 className="title">
              About{' '}
              <span role="img" aria-label="me">
                ☝️
              </span>
            </h1>
            <div>
              <p>
                Hey there! I'm Peng Chong from the little red dot, Singapore!
              </p>
              <p>
                I love getting immersed in data, frontend development, graphic
                design, ui/ux as well as exploring the ever changing modern
                practices and services in the tech world!
              </p>
              <p>
                Don't forget to <del>like and subscribe</del> get in touch with
                me on{' '}
                <a
                  href="https://www.linkedin.com/in/tanpc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
                ,{' '}
                <a
                  href="https://github.com/jokarz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </a>{' '}
                and{' '}
                <a
                  href="https://t.me/pengpengg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Telegram
                </a>{' '}
                <span role="img" aria-label="cool cool cool">
                  😎
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutMe;
