import React, { useState, useEffect } from 'react';
import { useSpring, animated as a } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const clamp = (val, min, max) => {
  if (val < min) {
    return min;
  } else if (val > max) {
    return max;
  } else {
    return val;
  }
};

const Glasses = ({ shade, type }) => {
  const [dragging, setDragging] = useState(false);

  const [{ xys }, set] = useSpring(() => ({
    config: { clamp: false, friction: 20, tension: 200, mass: 2 },
    xys: [~~(Math.random() * 4 - 2) * 80, -160, 1.1],
  }));

  const bind = useDrag(
    ({ down, movement: [x, y] }) => {
      x = clamp(x, -200, 200);
      y = clamp(y, -160, 160);
      setDragging(down);
      set.start({ xys: [down ? x : 0, down ? y : 0, down ? 1.15 : 1] });
    },
    {
      pointer: {
        touch: true, // uses touch on mobile
        capture: true, // don't use setPointerCapture (uses window)
      },
    }
  );

  useEffect(() => {
    let timer = setTimeout(() => {
      set.start({ xys: [0, 0, 1] });
    }, 400);
    return () => clearTimeout(timer);
  }, [set]);

  // useEffect(() => {
  //   if (typeof window !== `undefined`) {
  //     if (dragging && window.document.body.style.overflow === '') {
  //       window.document.body.style.overflow = 'hidden';
  //     } else if (
  //       !dragging &&
  //       window.document.body.style.overflow === 'hidden'
  //     ) {
  //       window.document.body.style.overflow = '';
  //     }
  //   }
  // }, [dragging]);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (dragging) {
        disableBodyScroll(window.document.body);
      } else {
        clearAllBodyScrollLocks();
      }
    }
  }, [dragging]);

  return (
    <a.g
      {...bind()}
      className="grabbable"
      style={{
        transform: xys.to((x, y, s) => {
          return `translate3d(${x}px,${y}px,0) scale(${s})`;
        }),
      }}
    >
      {!shade && type === 'round' ? (
        <>
          <line
            display="inline"
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeMiterlimit="10"
            x1="79.5"
            y1="112.5"
            x2="120.5"
            y2="112.5"
          />

          <image
            display="inline"
            width="30"
            height="30"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHdJREFUeNrsl8sNgDAMQ/tYif1H
YKZwRQiq8oktQXKt1BdbSuvQLlREzL1zYBm9i6ewu03wNnC0ATKhPTjZ0DM4CugRHBV0D5+aqVCq
3aq2KfZZrbbZrrjABf7gHNeTKbX6f/+xNfpYw5413loDvXWFyV7aVgEGANheQ1xj+YjWAAAAAElF
TkSuQmCC"
            transform="matrix(1 0 0 1 49 97)"
          ></image>

          <circle
            display="inline"
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="64"
            cy="112"
            r="14.5"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M60.5,102.8c-1,0.3-2.5,0.6-3.5,1.1c-3.5,1.9-5.6,6.8-2,12
C54.5,110.8,55.4,106.3,60.5,102.8z"
          />

          <image
            display="inline"
            width="30"
            height="30"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHdJREFUeNrsl8sNgDAMQ/tYif1H
YKZwRQiq8oktQXKt1BdbSuvQLlREzL1zYBm9i6ewu03wNnC0ATKhPTjZ0DM4CugRHBV0D5+aqVCq
3aq2KfZZrbbZrrjABf7gHNeTKbX6f/+xNfpYw5413loDvXWFyV7aVgEGANheQ1xj+YjWAAAAAElF
TkSuQmCC"
            transform="matrix(1 0 0 1 121 97)"
          ></image>

          <circle
            display="inline"
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="136"
            cy="112"
            r="14.5"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M132.5,102.8c-1,0.3-2.5,0.6-3.5,1.1c-3.5,1.9-5.6,6.8-2,12
C126.5,110.8,127.4,106.3,132.5,102.8z"
          />
        </>
      ) : shade && type === 'round' ? (
        <>
          <line
            display="inline"
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeMiterlimit="10"
            x1="79.5"
            y1="112.5"
            x2="120.5"
            y2="112.5"
          />
          <circle display="inline" fill="#1A1A1A" cx="64" cy="112" r="14.5" />

          <circle
            display="inline"
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="64"
            cy="112"
            r="14.5"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M60.5,102.8c-1,0.3-2.5,0.6-3.5,1.1c-3.5,1.9-5.6,6.8-2,12
C54.5,110.8,55.4,106.3,60.5,102.8z"
          />
          <circle display="inline" fill="#1A1A1A" cx="136" cy="112" r="14.5" />

          <circle
            display="inline"
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="136"
            cy="112"
            r="14.5"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M132.5,102.8c-1,0.3-2.5,0.6-3.5,1.1c-3.5,1.9-5.6,6.8-2,12
C126.5,110.8,127.4,106.3,132.5,102.8z"
          />
        </>
      ) : !shade && type === 'square' ? (
        <>
          <line
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeMiterlimit="10"
            x1="79.5"
            y1="112.5"
            x2="120.5"
            y2="112.5"
          />

          <image
            width="28"
            height="28"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFFJREFUeNrs1qERACAMQ9GGldh/
BGYqijvoIWkqyHdRzwYWcvduDwMwjp2J3VBkYxEFA9tRsLBVM3ICBQoUKFCgwB9A/okquYklR5h1
9acAAwDqeyNg+RVm4gAAAABJRU5ErkJggg=="
            transform="matrix(1 0 0 1 50 98)"
          ></image>
          <path
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M69.5,126H58.5
c-4.6,0-8.5-3.8-8.5-8.5v-11.1c0-4.6,3.8-8.5,8.5-8.5h11.1c4.6,0,8.5,3.8,8.5,8.5v11.1C78,122.2,74.2,126,69.5,126z"
          />
          <path
            fill="#FFFFFF"
            d="M53,115.5v-9.4c0-3.9,3.6-6.1,8-6.1c0,0-5,3-6,7C54,112,53,117.6,53,115.5z"
          />

          <image
            width="28"
            height="28"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFFJREFUeNrs1qERACAMQ9GGldh/
BGYqijvoIWkqyHdRzwYWcvduDwMwjp2J3VBkYxEFA9tRsLBVM3ICBQoUKFCgwB9A/okquYklR5h1
9acAAwDqeyNg+RVm4gAAAABJRU5ErkJggg=="
            transform="matrix(1 0 0 1 122 98)"
          ></image>
          <path
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M141.5,126h-11.1
c-4.6,0-8.5-3.8-8.5-8.5v-11.1c0-4.6,3.8-8.5,8.5-8.5h11.1c4.6,0,8.5,3.8,8.5,8.5v11.1C150,122.2,146.2,126,141.5,126z"
          />
          <path
            fill="#FFFFFF"
            d="M125,115.5v-9.4c0-3.9,3.6-6.1,8-6.1c0,0-5,3-6,7C126,112,125,117.6,125,115.5z"
          />
        </>
      ) : shade && type === 'square' ? (
        <>
          <line
            display="inline"
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeMiterlimit="10"
            x1="79.5"
            y1="112.5"
            x2="120.5"
            y2="112.5"
          />
          <path
            display="inline"
            fill="#1A1A1A"
            d="M69.5,126H58.5c-4.6,0-8.5-3.8-8.5-8.5v-11.1c0-4.6,3.8-8.5,8.5-8.5h11.1
c4.6,0,8.5,3.8,8.5,8.5v11.1C78,122.2,74.2,126,69.5,126z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M69.5,126
H58.5c-4.6,0-8.5-3.8-8.5-8.5v-11.1c0-4.6,3.8-8.5,8.5-8.5h11.1c4.6,0,8.5,3.8,8.5,8.5v11.1C78,122.2,74.2,126,69.5,126z"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M53,115.5v-9.4c0-3.9,3.6-6.1,8-6.1c0,0-5,3-6,7C54,112,53,117.6,53,115.5z"
          />
          <path
            display="inline"
            fill="#1A1A1A"
            d="M141.5,126h-11.1c-4.6,0-8.5-3.8-8.5-8.5v-11.1c0-4.6,3.8-8.5,8.5-8.5h11.1
c4.6,0,8.5,3.8,8.5,8.5v11.1C150,122.2,146.2,126,141.5,126z"
          />
          <path
            display="inline"
            fill="none"
            stroke="#333333"
            strokeWidth="3"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="
M141.5,126h-11.1c-4.6,0-8.5-3.8-8.5-8.5v-11.1c0-4.6,3.8-8.5,8.5-8.5h11.1c4.6,0,8.5,3.8,8.5,8.5v11.1
C150,122.2,146.2,126,141.5,126z"
          />
          <path
            display="inline"
            fill="#FFFFFF"
            d="M125,115.5v-9.4c0-3.9,3.6-6.1,8-6.1c0,0-5,3-6,7C126,112,125,117.6,125,115.5z"
          />
        </>
      ) : null}
    </a.g>
  );
};

Glasses.defaultProps = {
  shade: false,
  type: 'round',
};

export default Glasses;
